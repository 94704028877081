import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const SearchEngineOptimization = ({
  children,
  location,
  estimatedReading,
  meta,
}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `);

  const { title, description, image, siteUrl } = site.siteMetadata;

  const siteTitle = title;
  const siteDescription = description;
  const siteImage = image;

  const canonical = location && `${siteUrl}${location.pathname}`;
  const pageTitle = meta?.title ? `${meta.title}  —  ${siteTitle}` : siteTitle;
  const pageDescription = meta?.description || siteDescription;
  const robotsContent =
    meta?.robotsContent ||
    "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1";

  const socialType = meta?.ogType || "website";
  const socialTitle = meta?.title || siteTitle;
  const socialDescription = pageDescription;
  const socialImage = meta?.image?.images.fallback.src || siteImage;
  const socialImageAlt = meta?.alt || "Family I Love a.k.a. The Rebel Kellys";
  const socialImageWidth = "400";
  const socialImageHeight = "auto";
  const socialImageType = "image/jpeg";
  const socialLocale = "en_US";

  const facebookId = "592389829079215";

  const articlePublisher = "https://www.facebook.com/FamilyILove.Band";
  const articleCreatedAt = meta?.createdAt;
  const articleUpdatedAt = meta?.updatedAt;

  const twitterSite = "FamilyILoveBand";
  const twitterCreator = "FamilyILoveBand";
  const twitterCard = "summary_large_image";
  const twitterLabel = "Est. reading time";
  const twitterData = estimatedReading;

  return (
    <>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="robots" content={robotsContent} />
      <link rel="canonical" href={canonical} />

      <link rel="alternate" type="application/rss+xml" title={siteTitle} href="https://familyilove.com/rss.xml" />
      <link rel="shortcut icon" href={socialImage} />

      <meta property="article:publisher" content={articlePublisher} />
      {articleCreatedAt && (
        <meta property="article:published_time" content={articleCreatedAt} />
      )}
      {articleUpdatedAt && (
        <meta property="article:modified_time" content={articleUpdatedAt} />
      )}

      <meta property="og:title" content={socialTitle} />
      <meta property="og:description" content={socialDescription} />
      <meta property="og:url" content={canonical} />
      <meta property="og:locale" content={socialLocale} />
      <meta property="og:type" content={socialType} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={socialImage} />
      <meta property="og:image:alt" content={socialImageAlt} />
      <meta property="og:image:width" content={socialImageWidth} />
      <meta property="og:image:height" content={socialImageHeight} />
      <meta property="og:image:type" content={socialImageType} />
      <meta property="fb:app_id" content={facebookId} />

      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:site" content={twitterSite} />
      <meta name="twitter:creator" content={twitterCreator} />

      {estimatedReading && (
        <>
          <meta name="twitter:label1" content={twitterLabel} />
          <meta name="twitter:data1" content={twitterData} />
        </>
      )}

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400&display=swap&family=Cormorant+SC"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://sibforms.com/forms/end-form/build/sib-styles.css"
      />
      {children}
    </>
  );
};

export default SearchEngineOptimization;
