import React from "react";
import styled from "styled-components";

import SearchEngineOptimization from "../components/utility/SearchEngineOptimization";
import SocialMedia from "../components/utility/SocialMedia";

import UnsubscribeBanner from "../images/unsubscribe-banner.jpeg";

const UnsubscribeStyles = styled.div`
  padding: 0 10%;
  text-align: center;
  margin-bottom: 10%;

  img {
    margin-top: 4rem;
    width: 1000px;
    max-width: 100%;
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: center;
  }

  .social {
    margin-right: 4rem;
  }

  svg {
    width: 36px;
    height: 36px;
    top: 0.125em;
    color: var(--black);
  }
`;

export function Head({ location }) {
  return (
    <>
      <SearchEngineOptimization
        meta={{
          title: "Unsubscribe",
          robotsContent: "no index, follow",
        }}
        location={location}
      />
    </>
  );
}

export default function UnsubscribePage({ location }) {
  return (
    <>
      <UnsubscribeStyles>
        <img
          src={UnsubscribeBanner}
          alt="Looking for other ways to stay in touch with us?"
        />

        <h3>Follow us on social media:</h3>

        <SocialMedia />

        <div className="unsubscribe">
          <iframe
            title="Unsubscribe Form"
            width="540"
            height="305"
            src="https://e5d92ab6.sibforms.com/serve/MUIEAMZ1iW3L2eup5e66iA3t4kGI4Dwl1I7daDKDkEL9aU1NT3IZzuAxPhkhok5lsiQ8Ya4Oegr0dAc7-o8FVthCa0-yRs1U_dAwNGlfHIQeXGjjEGCFfJAUgVpaCANg3HuqltYgTLcc9PlTtffDqpsmvXO2Mk0MjVj4PnbjZwYIPMXTHY-NwXYXDyuaJl5lhlm-EtebMbuLdn7h"
            frameBorder="0"
            scrolling="auto"
            allowFullScreen
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "100%",
            }}
          ></iframe>
        </div>
      </UnsubscribeStyles>
    </>
  );
}
